import React, { useState, useEffect } from "react";
import { Observer } from "mobx-react";
import { motion } from "framer-motion";
import variants from "./variants";
import { useTranslation } from 'react-i18next';
import prStore from "../store";

import personalPaymentStripe from '../../../assets/images/personal-payment-stripe.svg'
import personalPaymentPayPal from '../../../assets/images/personal-payment-paypal.svg';
import companyPaymentIcon from '../../../assets/images/company-payment-icon.svg';
import styles from "./styles.module.css";

const PaymentMethod = () => {
  const [showFindYourTaste, setShowFindYourTaste] = useState(prStore.paymentMethod === 'promo' ? true : false);
  const [inputValue, setInputValue] = useState('');
  const [textareaValue, setTextareaValue] = useState('');

  const { t } = useTranslation();
  const onChangeHandler = (event) => {
    prStore.paymentMethod = event.target.value;
    
    if (event.target.value === 'promo') {
      setShowFindYourTaste(true)
      inputValue ? prStore.disableNextButton = false : prStore.disableNextButton = true;
    } else {
      setShowFindYourTaste(false)
    }
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    prStore.customerEmail = event.target.value;
    validate(event.target.value.trim() !== '');
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
    prStore.customerFeedback = event.target.value;
    validate(event.target.value.trim() !== '');
  };

  const validate = (isInputValid) => {
    isInputValid ? prStore.disableNextButton = false : prStore.disableNextButton = true;
  };

  useEffect(() => {
    prStore.paymentMethod === 'promo'
     ? inputValue ? prStore.disableNextButton = false : prStore.disableNextButton = true
     : prStore.disableNextButton = false;

    return () => prStore.disableNextButton = false;
  }, [prStore.paymentMethod]);

  return (
    <Observer render={() => (
      <React.Fragment>
        <motion.div
          className={styles.items}
          variants={variants.itemsVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {/* <motion.div className={styles.item} variants={variants.itemVariants}>
            <label className={styles.label} htmlFor="stripe">
              <div className="d-flex align-items-center">
                <input className={styles.radioInput} type="radio" id="stripe" name="choose-payment-method" value="stripe" checked={prStore.paymentMethod === "stripe"} onChange={onChangeHandler} />
                <div className={styles.radioIcon}></div>
                <div className={`${styles.radioTitle} f-bold`}>{t('pay-with-stripe')}</div>
              </div>
              <div className="d-flex align-items-center">
                <div className={styles.paymentImages}>
                  <img src={personalPaymentStripe} alt={t('pay-with-stripe')} />
                </div>
              </div>
            </label>
          </motion.div> */}
          <motion.div className={styles.item} variants={variants.itemVariants}>
            <label className={styles.label} htmlFor="paypal">
              <div className="d-flex align-items-center">
                <input className={styles.radioInput} type="radio" id="paypal" name="choose-payment-method" value="paypal" checked={prStore.paymentMethod === "paypal"} onChange={onChangeHandler} />
                <div className={styles.radioIcon}></div>
                <div className={`${styles.radioTitle} f-bold`}>{t('pay-with-paypal')}</div>
              </div>
              <div className="d-flex align-items-center">
                <div className={styles.paymentImages}>
                  <img src={personalPaymentPayPal} alt={t('pay-with-paypal')} />
                </div>
              </div>
            </label>
          </motion.div>
          <motion.div className={styles.item} variants={variants.itemVariants}>
            <label className={styles.label} htmlFor="company">
              <div className="d-flex align-items-center">
                <input className={styles.radioInput} type="radio" id="company" name="choose-payment-method" value="company" checked={prStore.paymentMethod === "company"} onChange={onChangeHandler} />
                <div className={styles.radioIcon}></div>
                <div className={`${styles.radioTitle} f-bold`}>{t('pay-by-company')}</div>
              </div>
              <div className="d-flex align-items-center">
                <div className={styles.paymentImages}>
                  {/* <img src={companyPaymentIcon} alt={t('pay-by-company')} /> */}
                </div>
              </div>
            </label>
          </motion.div>
          {/* <motion.div className={styles.item} variants={variants.itemVariants}>
            <label className={styles.label} htmlFor="promo">
              <div className="d-flex align-items-center">
                <input className={styles.radioInput} type="radio" id="promo" name="choose-payment-method" value="promo" checked={prStore.paymentMethod === "promo"} onChange={onChangeHandler} />
                <div className={styles.radioIcon}></div>
                <div className={`${styles.radioTitle} f-bold`}>{t('find-your-taste')}</div>
              </div>
              <div className="d-flex align-items-center">
                <div className={styles.paymentImages}>
                </div>
              </div>
            </label>
          </motion.div> */}
          {showFindYourTaste ? (
            <div className={styles.findYourTasteBox}>
              <motion.div className={styles.fieldBoxItem} variants={variants.itemVariants}>
                <div className={styles.field}>
                  <input 
                    type='email' 
                    name="email" 
                    placeholder={t("email")}
                    value={inputValue}
                    onChange={handleInputChange}
                    className={styles.input} 
                    required
                  />
                </div>
              </motion.div>
              {/* <motion.div className={styles.fieldBoxItem} variants={variants.itemVariants}>
                <div className={styles.field}>
                  <textarea 
                    className={styles.textarea} 
                    name="feedback" 
                    placeholder={t("feedback")}
                    maxlength="150"
                    value={textareaValue}
                    onChange={handleTextareaChange}
                  ></textarea>
                </div>
              </motion.div> */}
            </div>
          ) : null}
        </motion.div>
      </React.Fragment>
    )}/>
  );
};

export default PaymentMethod;


