import { makeAutoObservable } from "mobx";
import localStorageStore from "../../stores/localStorageStore";
import componentsStore from "../../stores/componentsStore";
import productsStore from "../../stores/productsStore";

import ApiManager from "../../ApiManager/APIManager";

export const PrStore = class {
  purchaseTransactionsInterval;
  clearPurchaseTransactionsInterval;
  paymentMethod = 'paypal';
  companiesData = [];
  selectedCompany = '';
  customerName = '';
  customerEmail = '';
  customerFeedback = '';
  disableNextButton = false;

  getCompanies = async () => {
    try {
      var res = await ApiManager.get(`companies`, {headers: {'Content-Language': localStorageStore.getCurrentLanguage()}});
      this.companiesData = res?.data;
    } catch (ex) {
      return false;
    } finally {}
  };

  createCorporateOrder = async () => {
    try {
      var res = await ApiManager.post(`create-corporate-order/${productsStore.pointId}`, {
        // "customerFullName": this.customerName,
        // "officeId": this.selectedCompany,
        "products": productsStore.selectedData ?? []
      }, {
        headers: {'Content-Language': localStorageStore.getCurrentLanguage()}
      });

      componentsStore.changePage(componentsStore.pages.paymentSuccess);

      var data = res?.data?.data;
      
      localStorage.setItem("transactionId", data.transactionId);
      localStorage.setItem("transactionIdForReceipt", data.transactionId);

      var second = 0;

      this.purchaseTransactionsInterval = setInterval(() => {
        this.purchaseTransactions(data.transactionId)
      }, data?.maxTimeInSeconds / data?.numberOfTries * 1000);

      this.clearPurchaseTransactionsInterval = setInterval(() => {
        if (second === data?.maxTimeInSeconds) {
          clearInterval(this.purchaseTransactionsInterval);
          clearInterval(this.clearPurchaseTransactionsInterval);
          this.purchaseTrDone("FAILED")
        }
        second++;
      }, 1000);

    } catch (ex) {
      return false;
    } finally {}
  };

  createPromoOrder = async () => {
    try {
      var res = await ApiManager.post(`create-promo-order/${productsStore.pointId}`, {
        "customerEmail": this.customerEmail,
        "customerFeedback": '-',
        "products": productsStore.selectedData ?? []
      }, {
        headers: {'Content-Language': localStorageStore.getCurrentLanguage()}
      });

      componentsStore.changePage(componentsStore.pages.paymentSuccess);

      var data = res?.data?.data;
      
      localStorage.setItem("transactionId", data.transactionId);
      localStorage.setItem("transactionIdForReceipt", data.transactionId);

      var second = 0;

      this.purchaseTransactionsInterval = setInterval(() => {
        this.purchaseTransactions(data.transactionId)
      }, data?.maxTimeInSeconds / data?.numberOfTries * 1000);

      this.clearPurchaseTransactionsInterval = setInterval(() => {
        if (second === data?.maxTimeInSeconds) {
          clearInterval(this.purchaseTransactionsInterval);
          clearInterval(this.clearPurchaseTransactionsInterval);
          this.purchaseTrDone("FAILED")
        }
        second++;
      }, 1000);

    } catch (ex) {
      return false;
    } finally {}
  };

  createStripeOrder = async () => {
    try {
      var res = await ApiManager.post(`create-order/${productsStore.pointId}`, {"products": productsStore.selectedData ?? []}, {headers: {'Content-Language': localStorageStore.getCurrentLanguage()}});
      var trId = res?.data?.data.transactionId;
      localStorage.setItem("transactionId", trId);
      localStorage.setItem("transactionIdForReceipt", trId);
      
      // eslint-disable-next-line no-restricted-globals
      location.href = res?.data?.data.checkoutURL;
    } catch (ex) {
      return false;
    } finally {}
  };

  createPayPalOrder = async () => {
    try {
      var res = await ApiManager.post(`create-paypal-order/${productsStore.pointId}`, {"products": productsStore.selectedData ?? []}, {headers: {'Content-Language': localStorageStore.getCurrentLanguage()}});
      var trId = res?.data?.data.transactionId;
      localStorage.setItem("transactionId", trId);
      localStorage.setItem("transactionIdForReceipt", trId);
      
      // eslint-disable-next-line no-restricted-globals
      location.href = res?.data?.data.checkoutURL;
    } catch (ex) {
      return false;
    } finally {}
  };

  purchaseTransactions = async (trId) => {
    try {
      var res = await ApiManager.get(`status/${trId}`, {headers: {'Content-Language': localStorageStore.getCurrentLanguage()}});
      var status = res?.data?.data?.status;

      if (status !== "PENDING" && status !== "CREATED") {
        localStorage.removeItem("transactionId");
        this.purchaseTrDone(status);
      }
    } catch (ex) {
      return false;
    } finally {}
  };


  buy = async (trId) => {
    try {
      var res = await ApiManager.get(`interval/${trId}`, {headers: {'Content-Language': localStorageStore.getCurrentLanguage()}});
      var data = res?.data;
      var second = 0;

      this.purchaseTransactionsInterval = setInterval(() => {
        this.purchaseTransactions(trId)
      }, data?.maxTimeInSeconds / data?.numberOfTries * 1000);

      this.clearPurchaseTransactionsInterval = setInterval(() => {
        if (second === data?.maxTimeInSeconds) {
          clearInterval(this.purchaseTransactionsInterval);
          clearInterval(this.clearPurchaseTransactionsInterval);
          this.purchaseTrDone("FAILED")
        }
        second++;
      }, 1000);
    } catch (ex) {
      return false;
    } finally {}
  };

  purchaseTrDone (status) {
    clearInterval(this.purchaseTransactionsInterval);
    clearInterval(this.clearPurchaseTransactionsInterval);
    status === "COMPLETED" 
      ? componentsStore.changePage(componentsStore.pages.success)
      : componentsStore.changePage(componentsStore.pages.error)
  }

  reset () {
    this.paymentMethod = 'paypal';
    this.companiesData = [];
    this.selectedCompany = '';
    this.customerName = '';
    this.customerEmail = '';
    this.customerFeedback = '';
    this.disableNextButton = false;
  }

  constructor() {
    makeAutoObservable(this);
  }
};

export default new PrStore();